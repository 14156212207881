<template>
  <c-flex
    flex="1"
    flex-dir="column"
    overflow="auto"
    pb="30px"
  >
    <c-box
      px="40px"
      py="40px"
    >
      <c-heading
        color="black.900"
        font-size="24px"
        font-weight="700"
        margin-bottom="40px"
        text-transform="uppercase"
      >
        KOMPOSISI TUBUH &amp; GAYA HIDUP
      </c-heading>
      <EditBodyCompositionForm
        v-model="bodyComposition"
        @submit="submit"
      />
    </c-box>
  </c-flex>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import EditBodyCompositionForm from './forms/edit-body-composition-form.vue'
import equal from 'fast-deep-equal'

export default {
  name: 'BodyCompositionEdit',
  components: { EditBodyCompositionForm },
  data() {
    return {
      bodyComposition: null,
      programId: null,
    }
  },
  computed: {
    ...mapState({
      data: (s) => s.mealPlan.kondisiTubuh,
    }),
    ...mapGetters({
      axios: 'axios',
    }),
    clientId() {
      return this.$route.params.clientId
    },
    submittedValue() {
      return {
        clientId: this.clientId,
        programId: this.programId,
        ..._.pick(this.bodyComposition, [
          'id',
          'fluidIntakeCondition',
          'fluidIntakeStandard',
          'fluidIntakeConclusion',
          'physicalActivityCondition',
          'physicalActivityStandard',
          'physicalActivityConclusion',
          'waistSize',
          'minimumStandardWaistSize',
          'maximumStandardWaistSize',
          'waistSizeConclusion',
        ]),
      }
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(val, old) {
        if (!equal(val, old)) {
          this.bodyComposition = {
            ...this.bodyComposition,
            ...val,
          }
        }
      },
    },
  },
  async mounted() {
    let fluidIntakeRe = /(\d+)\s?gelas\s?\/\s?hari/gi

    await this.getKondisiTubuh(this.clientId)
    this.programId = await this.$store
      .dispatch('mealPlan/getLatestProgramForNutri', this.clientId)
      .then((it) => it.id)
    await this.axios
      .get(`/v1/nutritionist/quizioner/${this.programId}`)
      .then((r) => r.data.data)
      .then((r) => {

        let fluidIntakeCondition = fluidIntakeRe.exec(
          r.dietAndEatingHistory.fluidConsumption,
        )?.[1]
        if (fluidIntakeCondition != null)
          fluidIntakeCondition = parseInt(fluidIntakeCondition)

        this.$set(
          this.bodyComposition,
          'fluidIntakeCondition',
          fluidIntakeCondition,
        )
        this.$set(
          this.bodyComposition,
          'waistSize',
          r.anthropometryData.waistSize,
        )
      })
  },
  methods: {
    ...mapActions({
      getKondisiTubuh: 'mealPlan/getKondisiTubuh',
      updateKondisiTubuh: 'mealPlan/updateKondisiTubuh',
      createKondisiTubuh: 'mealPlan/createKondisiTubuh',
    }),
    submit() {
      if (this.submittedValue.id != null) {
        this.updateKondisiTubuh({ kondisiTubuh: this.submittedValue }).then(
          () => {
            this.$router.push({
              name: 'nutri.profile-gizi',
              params: { clientId: this.clientId },
            })
          },
        )
      } else {
        this.createKondisiTubuh(this.submittedValue).then(() =>
          this.$router.push({
            name: 'nutri.profile-gizi',
            params: { clientId: this.clientId },
          }),
        )
      }
    },
  },
}
</script>
