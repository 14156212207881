<template>
  <c-box
    w="100%"
    max-width="590px"
  >
    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kondisi Asupan Cairan (gelas / hari)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.fluidIntakeCondition"
          type="text"
          placeholder="Masukkan Kondisi Asupan Cairan (gelas / hari)"
          height="62px"
          :data-model="value.fluidIntakeCondition"
        />
      </c-input-group>
    </c-form-control>

    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Standar Asupan Cairan (gelas / hari)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.fluidIntakeStandard"
          type="text"
          placeholder="Masukkan Standar Asupan Cairan (gelas / hari)"
          height="62px"
        />
      </c-input-group>
    </c-form-control>

    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kesimpulan Asupan Cairan
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.fluidIntakeConclusion"
          placeholder="Kesimpulan Asupan Cairan"
          height="62px"
        >
          <option value="adequate">
            Cukup
          </option>
          <option value="deficient">
            Kurang
          </option>
          <option value="excessive">
            Lebih
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kondisi Aktifitas Fisik (menit / minggu)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.physicalActivityCondition"
          type="text"
          placeholder="Masukkan Kondisi Aktifitas Fisik (menit / minggu)"
          height="62px"
          padding-right="60px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Standar Aktifitas Fisik (menit / minggu)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.physicalActivityStandard"
          type="text"
          placeholder="Masukkan Standar Aktifitas Fisik (menit / minggu)"
          height="62px"
          padding-right="60px"
        />
      </c-input-group>
    </c-form-control>

    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kesimpulan Aktifitas Fisik
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.physicalActivityConclusion"
          placeholder="Kesimpulan Aktifitas Fisik"
          height="62px"
        >
          <option value="adequate">
            Cukup
          </option>
          <option value="deficient">
            Kurang
          </option>
          <option value="excessive">
            Lebih
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kondisi Lingkar Pinggang (cm)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.waistSize"
          type="text"
          placeholder="Masukkan Kondisi Lingkar Pinggang (cm)"
          height="62px"
          padding-right="70px"
        />
      </c-input-group>
    </c-form-control>

    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Minimum Standar Lingkar Pinggang (cm)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.minimumStandardWaistSize"
          type="text"
          placeholder="Masukkan Minimum Standar Lingkar Pinggang (cm)"
          height="62px"
          padding-right="90px"
        />
      </c-input-group>
    </c-form-control>

    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Maximum Standar Lingkar Pinggang (cm)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="value.maximumStandardWaistSize"
          type="text"
          placeholder="Masukkan Maximum Standar Lingkar Pinggang (cm)"
          height="62px"
          padding-right="90px"
        />
      </c-input-group>
    </c-form-control>
    <c-form-control
      is-required
      margin-bottom="80px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kesimpulan Lingkar Pinggang
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="value.waistSizeConclusion"
          placeholder="Kesimpulan Lingkar Pinggang"
          height="62px"
        >
          <option value="adequate">
            Cukup
          </option>
          <option value="deficient">
            Kurang
          </option>
          <option value="excessive">
            Lebih
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <c-form-control
      is-required
      margin-bottom="30px"
      display="flex"
    >
      <c-button
        background-color="superLightGray.900"
        px="26px"
        font-size="18px"
        font-weight="500"
        py="26px"
        border-radius="58px"
        width="100%"
        color="darkGray.900"
        margin-right="20px"
        margin-bottom="20px"
        as="router-link"
        :to="{ name: 'nutri.profile-gizi' }"
      >
        Batal
      </c-button>
      <c-button
        background-color="primary.400"
        px="26px"
        font-size="18px"
        font-weight="500"
        py="26px"
        border-radius="58px"
        width="100%"
        color="white"
        @click="$emit('submit')"
      >
        Simpan
      </c-button>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: 'EditBodyCompositionForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
