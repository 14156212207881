var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "590px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kondisi Asupan Cairan (gelas / hari) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Masukkan Kondisi Asupan Cairan (gelas / hari)",
      "height": "62px",
      "data-model": _vm.value.fluidIntakeCondition
    },
    model: {
      value: _vm.value.fluidIntakeCondition,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "fluidIntakeCondition", $$v);
      },
      expression: "value.fluidIntakeCondition"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Standar Asupan Cairan (gelas / hari) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Masukkan Standar Asupan Cairan (gelas / hari)",
      "height": "62px"
    },
    model: {
      value: _vm.value.fluidIntakeStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "fluidIntakeStandard", $$v);
      },
      expression: "value.fluidIntakeStandard"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kesimpulan Asupan Cairan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Kesimpulan Asupan Cairan",
      "height": "62px"
    },
    model: {
      value: _vm.value.fluidIntakeConclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "fluidIntakeConclusion", $$v);
      },
      expression: "value.fluidIntakeConclusion"
    }
  }, [_c('option', {
    attrs: {
      "value": "adequate"
    }
  }, [_vm._v(" Cukup ")]), _c('option', {
    attrs: {
      "value": "deficient"
    }
  }, [_vm._v(" Kurang ")]), _c('option', {
    attrs: {
      "value": "excessive"
    }
  }, [_vm._v(" Lebih ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kondisi Aktifitas Fisik (menit / minggu) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Masukkan Kondisi Aktifitas Fisik (menit / minggu)",
      "height": "62px",
      "padding-right": "60px"
    },
    model: {
      value: _vm.value.physicalActivityCondition,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "physicalActivityCondition", $$v);
      },
      expression: "value.physicalActivityCondition"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Standar Aktifitas Fisik (menit / minggu) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Masukkan Standar Aktifitas Fisik (menit / minggu)",
      "height": "62px",
      "padding-right": "60px"
    },
    model: {
      value: _vm.value.physicalActivityStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "physicalActivityStandard", $$v);
      },
      expression: "value.physicalActivityStandard"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kesimpulan Aktifitas Fisik ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Kesimpulan Aktifitas Fisik",
      "height": "62px"
    },
    model: {
      value: _vm.value.physicalActivityConclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "physicalActivityConclusion", $$v);
      },
      expression: "value.physicalActivityConclusion"
    }
  }, [_c('option', {
    attrs: {
      "value": "adequate"
    }
  }, [_vm._v(" Cukup ")]), _c('option', {
    attrs: {
      "value": "deficient"
    }
  }, [_vm._v(" Kurang ")]), _c('option', {
    attrs: {
      "value": "excessive"
    }
  }, [_vm._v(" Lebih ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kondisi Lingkar Pinggang (cm) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Masukkan Kondisi Lingkar Pinggang (cm)",
      "height": "62px",
      "padding-right": "70px"
    },
    model: {
      value: _vm.value.waistSize,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "waistSize", $$v);
      },
      expression: "value.waistSize"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Minimum Standar Lingkar Pinggang (cm) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Masukkan Minimum Standar Lingkar Pinggang (cm)",
      "height": "62px",
      "padding-right": "90px"
    },
    model: {
      value: _vm.value.minimumStandardWaistSize,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "minimumStandardWaistSize", $$v);
      },
      expression: "value.minimumStandardWaistSize"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Maximum Standar Lingkar Pinggang (cm) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Masukkan Maximum Standar Lingkar Pinggang (cm)",
      "height": "62px",
      "padding-right": "90px"
    },
    model: {
      value: _vm.value.maximumStandardWaistSize,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "maximumStandardWaistSize", $$v);
      },
      expression: "value.maximumStandardWaistSize"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "80px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kesimpulan Lingkar Pinggang ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Kesimpulan Lingkar Pinggang",
      "height": "62px"
    },
    model: {
      value: _vm.value.waistSizeConclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "waistSizeConclusion", $$v);
      },
      expression: "value.waistSizeConclusion"
    }
  }, [_c('option', {
    attrs: {
      "value": "adequate"
    }
  }, [_vm._v(" Cukup ")]), _c('option', {
    attrs: {
      "value": "deficient"
    }
  }, [_vm._v(" Kurang ")]), _c('option', {
    attrs: {
      "value": "excessive"
    }
  }, [_vm._v(" Lebih ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px",
      "display": "flex"
    }
  }, [_c('c-button', {
    attrs: {
      "background-color": "superLightGray.900",
      "px": "26px",
      "font-size": "18px",
      "font-weight": "500",
      "py": "26px",
      "border-radius": "58px",
      "width": "100%",
      "color": "darkGray.900",
      "margin-right": "20px",
      "margin-bottom": "20px",
      "as": "router-link",
      "to": {
        name: 'nutri.profile-gizi'
      }
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "background-color": "primary.400",
      "px": "26px",
      "font-size": "18px",
      "font-weight": "500",
      "py": "26px",
      "border-radius": "58px",
      "width": "100%",
      "color": "white"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('submit');
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }